// Media of at most the maximum and minimum breakpoint widths. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint.

@mixin media-breakpoint-direction($direction, $name, $breakpoints: $grid-breakpoints) {
	@if $direction == up {
		$min: breakpoint-min($name, $breakpoints);

		@if $min {
			@media (min-width: $min) {
				@content;
			}
		} @else {
			@content;
		}
	} @else if $direction == down {
		$max: breakpoint-max($name, $breakpoints);

		@if $max {
			@media (max-width: $max) {
				@content;
			}
		}
	}
}
