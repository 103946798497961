//
// BlockUI
//

.blockui {
	position: relative;

	.blockui-overlay {
		transition: all 0.3s ease;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: var(--#{$prefix}blockui-overlay-bg);

		.spinner-border {
			height: 1.35rem;
			width: 1.35rem;
		}
	}

	.blockui-message {
		display: flex;
		align-items: center;
		@include border-radius($border-radius);
		box-shadow: var(--#{$prefix}blockui-message-box-shadow);
		background-color: var(--#{$prefix}blockui-message-bg);
		color: var(--#{$prefix}gray-700);
		font-weight: $font-weight-bold;
		margin: 0 !important;
		width: auto;
		padding: 0.85rem 1.75rem !important;

		.spinner-border {
			margin-right: 0.65rem;
		}
	}
}

@if $enable-dark-mode {
	@include color-mode(dark) {
		.blockui {
			--#{$prefix}blockui-overlay-bg: #{$blockui-overlay-bg-dark};
			--#{$prefix}blockui-message-bg: #{$blockui-message-bg-dark};
			--#{$prefix}blockui-message-box-shadow: #{$blockui-message-box-shadow-dark};
		}
	}
}
