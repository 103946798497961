//
// Footer
//

// General mode
.app-footer {
	transition: $app-footer-transition;
	display: flex;
	align-items: center;
	background-color: var(--#{$prefix}app-footer-bg-color);
	border-top: var(--#{$prefix}app-footer-border-top);
}

// Desktop mode
@include media-breakpoint-up(lg) {
	// Base
	.app-footer {
		@include property(z-index, $app-footer-z-index);
	}

	// Vars
	:root {
		--#{$prefix}app-footer-height: #{$app-footer-height};
	}

	// States
	.app-footer {
		height: var(--#{$prefix}app-footer-height);

		[data-kt-app-footer-fixed='true'] & {
			@include property(z-index, $app-footer-fixed-z-index);
			box-shadow: var(--#{$prefix}app-footer-box-shadow);
			position: fixed;
			left: 0;
			right: 0;
			bottom: 0;
		}
	}

	// Integration
	.app-footer {
		// Sidebar
		[data-kt-app-sidebar-fixed='true'][data-kt-app-sidebar-push-footer='true'] & {
			left: calc(
				var(--#{$prefix}app-sidebar-width) + var(--#{$prefix}app-sidebar-gap-start, 0px) + var(--#{$prefix}app-sidebar-gap-end, 0px)
			);
		}

		// Sidebar Panel
		[data-kt-app-sidebar-panel-fixed='true'][data-kt-app-sidebar-panel-push-footer='true'] & {
			left: calc(
				var(--#{$prefix}app-sidebar-width) + var(--#{$prefix}app-sidebar-gap-start, 0px) + var(--#{$prefix}app-sidebar-gap-end, 0px) +
					var(--#{$prefix}app-sidebar-panel-width) + var(--#{$prefix}app-sidebar-panel-gap-start, 0px) +
					var(--#{$prefix}app-sidebar-panel-gap-end, 0px)
			);
		}

		// Aside
		[data-kt-app-aside-fixed='true'][data-kt-app-aside-push-footer='true'] & {
			right: calc(var(--#{$prefix}app-aside-width) + var(--#{$prefix}app-aside-gap-start, 0px) + var(--#{$prefix}app-aside-gap-end, 0px));
		}
	}
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
	// Base
	.app-footer {
		@include property(z-index, $app-footer-z-index-mobile);
	}

	// Vars
	body {
		--#{$prefix}app-footer-height: #{$app-footer-height-mobile};
	}

	// States
	.app-footer {
		height: var(--#{$prefix}app-footer-height);

		[data-kt-app-footer-fixed-mobile='true'] & {
			@include property(z-index, $app-footer-fixed-z-index-mobile);
			box-shadow: var(--#{$prefix}app-footer-box-shadow);
			position: fixed;
			left: 0;
			right: 0;
			bottom: 0;
		}
	}
}
