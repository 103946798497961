//
// Avatar
//

// Base
.image-input {
	position: relative;
	display: inline-block;
	@include border-radius($border-radius);
	background-repeat: no-repeat;
	background-size: cover;

	// Empty state
	&:not(.image-input-empty) {
		background-image: none !important;
	}

	// Wrapper
	.image-input-wrapper {
		width: 120px;
		height: 120px;
		@include border-radius($border-radius);
		background-repeat: no-repeat;
		background-size: cover;
	}

	// Actions
	[data-kt-image-input-action] {
		cursor: pointer;
		position: absolute;
		transform: translate(-50%, -50%);
	}

	// Change Button
	[data-kt-image-input-action='change'] {
		left: 100%;
		top: 0;

		input {
			width: 0 !important;
			height: 0 !important;
			overflow: hidden;
			opacity: 0;
		}
	}

	// Cancel & Remove Buttons
	[data-kt-image-input-action='cancel'],
	[data-kt-image-input-action='remove'] {
		position: absolute;
		left: 100%;
		top: 100%;
	}

	[data-kt-image-input-action='cancel'] {
		display: none;
	}

	// Input Changed State
	&.image-input-changed {
		[data-kt-image-input-action='cancel'] {
			display: flex;
		}

		[data-kt-image-input-action='remove'] {
			display: none;
		}
	}

	// Input Empty State
	&.image-input-empty {
		[data-kt-image-input-action='remove'],
		[data-kt-image-input-action='cancel'] {
			display: none;
		}
	}

	// Circle style
	&.image-input-circle {
		border-radius: 50%;

		// Wrapper
		.image-input-wrapper {
			border-radius: 50%;
		}

		// Change Control
		[data-kt-image-input-action='change'] {
			left: 100%;
			top: 0;
			transform: translate(-100%, 0%);
		}

		// Cancel & Remove Buttons
		[data-kt-image-input-action='cancel'],
		[data-kt-image-input-action='remove'] {
			left: 100%;
			top: 100%;
			transform: translate(-100%, -100%);
		}
	}

	// Bordered style
	&.image-input-outline {
		.image-input-wrapper {
			border: 3px solid var(--#{$prefix}body-bg);
			box-shadow: var(--#{$prefix}box-shadow);
		}
	}
}
