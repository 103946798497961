//
// jsTree
//

.jstree-default {
	.jstree-anchor {
		color: var(--#{$prefix}gray-700);
		padding: 0 8px 0 4px;
	}

	.jstree-icon {
		color: var(--#{$prefix}gray-700);

		font-size: 1.3rem;

		&.la {
			font-size: 1.5rem;
		}
		&.fa {
			font-size: 1.2rem;
		}
	}

	.jstree-disabled {
		cursor: not-allowed;
		line-height: auto;
		height: auto;
		opacity: 0.7;

		.jstree-icon {
			color: var(--#{$prefix}gray-700);
		}
	}

	.jstree-clicked {
		border: 0;
		background: var(--#{$prefix}gray-100);
		box-shadow: none;
	}

	.jstree-hovered {
		border: 0;
		background-color: var(--#{$prefix}gray-100);
		box-shadow: none;
	}

	.jstree-wholerow-clicked,
	.jstree-wholerow-clicked {
		background: var(--#{$prefix}gray-200);
		box-shadow: none;
	}

	.jstree-wholerow-hovered,
	&.jstree-wholerow .jstree-wholerow-hovered {
		border: 0;
		background-color: var(--#{$prefix}gray-100);
		box-shadow: none;
	}
}

.jstree-open > .jstree-anchor > .fa-folder:before {
	margin-left: 2px;
	content: '\f07c';
}

.jstree-open > .jstree-anchor > .la-folder:before {
	margin-left: 2px;
	content: '\f200';
}

.jstree-default.jstree-rtl .jstree-node {
	background-position: 100% 1px#{'/*rtl:ignore*/'} !important;
}

.jstree-default.jstree-rtl .jstree-last {
	background: transparent #{'/*rtl:ignore*/'};
	background-repeat: no-repeat;
}

.jstree-rtl .jstree-anchor {
	padding: 0 4px 0 8px#{'/*rtl:ignore*/'};
}

// context menu
.vakata-context,
.vakata-context ul {
	padding: 0.5rem 0;
	min-width: 150px;
	font-size: 1rem;
	font-family: $font-family-base;
	background: var(--#{$prefix}body-bg);
	box-shadow: var(--#{$prefix}dropdown-box-shadow);
	border: 0;
	@include border-radius($border-radius);

	li {
		padding: 0;
		border: 0;

		a {
			padding: 0rem 1.2rem;
			border: 0;
			//line-height: 2.2em;

			i {
				display: none;
			}

			.vakata-contextmenu-sep {
				display: none;
			}

			span,
			ins {
				display: none;
				border: 0 !important;
			}
		}
	}

	.vakata-context-hover > a,
	li a:hover {
		margin: 0;
		background-color: var(--#{$prefix}gray-100);
		color: var(--#{$prefix}primary);
		box-shadow: none;

		.span,
		.ins {
			border: 0 !important;
		}
	}
}

.vakata-context .vakata-context-separator a,
.vakata-context-rtl .vakata-context-separator a {
	margin: 0;
	border: 0;
	height: 2px;
	background-color: var(--#{$prefix}gray-200);
}

.jstree-rename-input {
	outline: none !important;
	padding: 2px 6px !important;
	margin-right: -4px !important;
	background-color: var(--#{$prefix}gray-100) !important;
	border: 1px solid var(--#{$prefix}gray-100) !important;
	@include border-radius($border-radius);
}
