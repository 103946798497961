//
//  Flatpickr
//

$flatpickr-padding-y: 0.5rem;
$flatpickr-padding-x: 1rem;
$flatpickr-width: 280px;
$flatpickr-width-week-numbers: 325px;
$flatpickr-day-height: 36px;
$flatpickr-day-width: 100%;
$flatpickr-time-height: 46px;

// Container
.flatpickr-calendar {
	width: $flatpickr-width !important;
	font-family: inherit;
	border: 0;
	border-radius: 0;
	box-shadow: var(--#{$prefix}dropdown-box-shadow);
	background-color: var(--#{$prefix}body-bg);
	@include border-radius($dropdown-border-radius);

	// Remow arrows
	&:before,
	&:after {
		display: none;
	}

	// Week numbers
	&.hasWeeks {
		width: $flatpickr-width-week-numbers !important;
	}
}

// Months container
.flatpickr-months {
	padding: 0 $flatpickr-padding-x;
	padding-top: $flatpickr-padding-y;
}

// Inner container
.flatpickr-innerContainer {
	padding: $flatpickr-padding-y $flatpickr-padding-x;
}

// Day containers
.flatpickr-days,
.dayContainer {
	width: 100% !important;
	min-width: 100% !important;
	max-width: 100% !important;
}

// Months
.flatpickr-months {
	.flatpickr-month {
		background: transparent;
		color: var(--#{$prefix}gray-600);
		fill: var(--#{$prefix}gray-600);
		height: 46px;
	}

	.flatpickr-prev-month,
	.flatpickr-next-month {
		display: flex;
		align-items: center;
		justify-content: center;
		@include border-radius($border-radius);
		top: 1rem;

		svg {
			fill: var(--#{$prefix}gray-500);
			height: 13px;
			width: 13px;
		}

		&:hover {
			background: var(--#{$prefix}gray-100);

			svg {
				fill: var(--#{$prefix}gray-700);
			}
		}
	}

	.flatpickr-prev-month.flatpickr-prev-month,
	.flatpickr-next-month.flatpickr-prev-month {
		/*rtl:begin:ignore*/
		left: $flatpickr-padding-x;
		/*rtl:end:ignore*/
	}

	.flatpickr-prev-month.flatpickr-next-month,
	.flatpickr-next-month.flatpickr-next-month {
		/*rtl:begin:ignore*/
		right: $flatpickr-padding-x;
		/*rtl:end:ignore*/
	}
}

// Month selector
.flatpickr-current-month {
	font-weight: $font-weight-semibold;
	color: inherit;

	.numInputWrapper {
		@include border-radius($border-radius);
		width: 65px;

		span {
			&.arrowUp {
				@include border-top-end-radius($border-radius);
			}

			&.arrowDown {
				@include border-bottom-end-radius($border-radius);
			}
		}
	}

	.flatpickr-monthDropdown-months {
		border: 0 !important;
		background-color: var(--#{$prefix}body-bg);
		font-size: 1rem;
		color: var(--#{$prefix}gray-700);
		font-weight: $font-weight-semibold;
		padding: 0.5rem 0.75rem;
		margin-right: 0.5rem;
		outline: none !important;
		@include border-radius($border-radius);
		appearance: none;
		-moz-appearance: none;
		-webkit-appearance: none;

		&:hover {
			background: var(--#{$prefix}gray-100);
		}

		.flatpickr-monthDropdown-month {
			font-size: 1rem;
			color: var(--#{$prefix}gray-700);
			font-weight: $font-weight-semibold;
			background-color: transparent;
			outline: none;
			padding: 0;
		}
	}

	span.cur-month {
		color: var(--#{$prefix}gray-700);
		font-size: 1rem;
		font-weight: $font-weight-semibold;

		&:hover {
			background: var(--#{$prefix}gray-100);
		}
	}

	input.cur-year {
		color: var(--#{$prefix}gray-700);
		font-size: 1.1rem !important;
		padding: 0.5rem 0.75rem;
		font-weight: $font-weight-semibold;
		outline: 0 !important;
	}
}

// Week days
span.flatpickr-weekday {
	color: var(--#{$prefix}gray-800);
	font-size: 1rem;
	font-weight: $font-weight-bold;
}

// Time
.flatpickr-time {
	@include border-bottom-radius($border-radius);

	.flatpickr-calendar.hasTime & {
		height: height;
		line-height: height;
		max-height: height;
		border-top: 1px solid var(--#{$prefix}gray-100);
	}

	.numInputWrapper {
		height: height;
	}

	.flatpickr-am-pm {
		color: var(--#{$prefix}gray-700);
		font-size: 1rem;
		font-weight: $font-weight-semibold;
	}

	input {
		&.flatpickr-hour,
		&.flatpickr-minute {
			color: var(--#{$prefix}gray-700);
			font-size: 1rem;
			font-weight: $font-weight-semibold;
		}
	}

	input:hover,
	.flatpickr-am-pm:hover,
	input:focus,
	.flatpickr-am-pm:focus {
		background: transparent;
	}
}

// Number input
.numInputWrapper {
	span {
		border-left: 0 !important;
		border-top: 0 !important;
		border-bottom: 0 !important;
		border-right: 0 !important;

		&:hover {
			background: transparent !important;
		}

		&:after {
			top: 50% !important;
			transform: translateY(-50%);
		}

		&.arrowUp {
			&:after {
				border-bottom-color: var(--#{$prefix}gray-500) !important;
			}

			&:hover {
				&:after {
					border-bottom-color: var(--#{$prefix}gray-700) !important;
				}
			}
		}

		&.arrowDown {
			&:after {
				border-top-color: var(--#{$prefix}gray-500) !important;
			}

			&:hover {
				&:after {
					border-top-color: var(--#{$prefix}gray-700) !important;
				}
			}
		}
	}

	&:hover {
		background: transparent;
	}
}

// Day
.flatpickr-day {
	font-size: 1rem;
	@include border-radius($border-radius);
	box-shadow: none !important;
	height: $flatpickr-day-height;
	width: $flatpickr-day-width;
	max-width: 100% !important;
	margin: 0;
	line-height: $flatpickr-day-height;
	color: var(--#{$prefix}gray-600);
	margin-top: 0 !important;

	&.inRange,
	&.prevMonthDay.inRange,
	&.nextMonthDay.inRange,
	&.today.inRange,
	&.prevMonthDay.today.inRange,
	&.nextMonthDay.today.inRange,
	&:hover,
	&.prevMonthDay:hover,
	&.nextMonthDay:hover,
	&:focus,
	&.prevMonthDay:focus,
	&.nextMonthDay:focus {
		cursor: pointer;
		outline: 0;
		background: var(--#{$prefix}component-hover-bg);
		color: var(--#{$prefix}component-hover-color);
		border-color: transparent;
	}

	// Today
	&.today {
		background: var(--#{$prefix}gray-100);
		color: var(--#{$prefix}gray-600);
		border-color: transparent;

		&:hover,
		&:focus {
			border-color: transparent;
			background: var(--#{$prefix}gray-200);
			color: var(--#{$prefix}gray-700);
		}
	}

	// Selected
	&.selected,
	&.startRange,
	&.endRange,
	&.selected.inRange,
	&.startRange.inRange,
	&.endRange.inRange,
	&.selected:focus,
	&.startRange:focus,
	&.endRange:focus,
	&.selected:hover,
	&.startRange:hover,
	&.endRange:hover,
	&.selected.prevMonthDay,
	&.startRange.prevMonthDay,
	&.endRange.prevMonthDay,
	&.selected.nextMonthDay,
	&.startRange.nextMonthDay,
	&.endRange.nextMonthDay {
		background: var(--#{$prefix}component-active-bg);
		color: var(--#{$prefix}component-active-color);
		border-color: transparent;
	}

	&.inRange,
	&.prevMonthDay.inRange,
	&.nextMonthDay.inRange,
	&.today.inRange,
	&.prevMonthDay.today.inRange,
	&.nextMonthDay.today.inRange,
	&:hover,
	&.prevMonthDay:hover,
	&.nextMonthDay:hover,
	&:focus,
	&.prevMonthDay:focus,
	&.nextMonthDay:focus {
		cursor: pointer;
		outline: 0;
		background: var(--#{$prefix}component-hover-bg);
		color: var(--#{$prefix}component-hover-color);
		border-color: transparent;
	}

	// Today
	&.today {
		border-color: transparent;
	}

	&.today:hover,
	&.today:focus {
		border-color: transparent;
		background: var(--#{$prefix}gray-100);
		color: var(--#{$prefix}gray-600);
	}

	// Selected
	&.selected,
	&.startRange,
	&.endRange,
	&.selected.inRange,
	&.startRange.inRange,
	&.endRange.inRange,
	&.selected:focus,
	&.startRange:focus,
	&.endRange:focus,
	&.selected:hover,
	&.startRange:hover,
	&.endRange:hover,
	&.selected.prevMonthDay,
	&.startRange.prevMonthDay,
	&.endRange.prevMonthDay,
	&.selected.nextMonthDay,
	&.startRange.nextMonthDay,
	&.endRange.nextMonthDay {
		background: var(--#{$prefix}component-active-bg);
		color: var(--#{$prefix}component-active-color);
		border-color: transparent;
	}

	// Disabled
	&.flatpickr-disabled,
	&.flatpickr-disabled:hover,
	&.prevMonthDay,
	&.nextMonthDay,
	&.notAllowed,
	&.notAllowed.prevMonthDay,
	&.notAllowed.nextMonthDay {
		color: var(--#{$prefix}gray-400);
		background: transparent;
		border-color: transparent;
	}
	&.flatpickr-disabled,
	&.flatpickr-disabled:hover {
		cursor: not-allowed;
		color: var(--#{$prefix}gray-400);
	}
}

// Week numbers
.flatpickr-weekwrapper {
	margin-right: 5px;
}
