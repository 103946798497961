//
// fslightbox
//

.fslightbox-slide-btn {
	border-radius: $border-radius;
}

.fslightbox-toolbar {
	border-bottom-left-radius: $border-radius;
}
